@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Pacifico&family=Sofia+Sans+Condensed:ital@1&display=swap");

:root {
  --backgroundGradient: linear-gradient(95deg, #f2a244 0%, #e751ff 100%);
  --purple: #dc4eff;
  --purpleGradient: linear-gradient(90deg, #9444c5 0%, #bc4fff 53.12%, #9444c5 100%);
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #070933;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
a,
p {
  margin: 0;
  padding: 0;
}
